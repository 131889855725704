import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import RequestDemoForm from "../components/forms/requestDemoForm"
import TextGroup from "../components/ui/textGroup"
import styled from "styled-components"
import { Mail, Phone } from "react-feather"

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }

  a {
    color: #222222;

    :hover{
      color: #000000;
    }
  }
`;

const RequestDemoPage = () => (
  <Layout>
    <SEO title="Request a Demo" />
    <HeroText
      statement="Request Demo"
      caption="Would you like to receive an obligation free demo?"
    ></HeroText>

    <TextGroup
      heading="Enjoy a Guided Tour"
      text={<><p>Want to know more about Beyond Attendance but don't know where to start? Fill out the demo request form and we will be in touch to schedule an obligation free demo of the product.</p>
        <ContactContainer>
          <div><Mail size="1rem" color="#d34400" />&nbsp;<a href="mailto:sales@beyondattendance.com">sales@beyondattendance.com</a></div>
          <div><Mail size="1rem" color="#d34400" />&nbsp;<a href="mailto:support@beyondattendance.com">support@beyondattendance.com</a><br /></div>
          <div><Phone size="1rem" color="#d34400" />&nbsp;<a href="tel:+27317648550">+27 (0)31 764 8550</a></div>
        </ContactContainer>
      </>}>
      <RequestDemoForm />

    </TextGroup>

  </Layout>
)

export default RequestDemoPage
